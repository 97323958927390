<template>
  <BaseTooltipModal v-if="freebet" :link="readMoreUrl">
    <template #title>
      {{ t('bonuses.freebetModalTitle', { amount: freebetAmount }) }}
    </template>
    <template #subtitle>
      {{ t('bonuses.freebetModalSubTitle') }}
    </template>

    <TooltipModalBlock
      :title="t('bonuses.tooltipModalParameters')"
      data-t="reward-block"
    >
      <TooltipModalBlockItem
        :termin="t('bonuses.tooltipModalRewardCurrency')"
        :description="freebet.currencyCode"
      />
    </TooltipModalBlock>
    <TooltipModalBlock
      :title="t('bonuses.tootlipModalConditions')"
      data-t="conditions-block"
    >
      <TooltipModalBlockItem
        v-if="minBetRate"
        :termin="t('bonuses.tooltipModalSportMin')"
        :description="formattedMinBetRate"
      />
      <TooltipModalBlockItem
        v-if="maxBetRate"
        :termin="t('bonuses.tooltipModalSportMax')"
        :description="formattedMaxBetRate"
      />
      <TooltipModalBlockItem
        :termin="t('bonuses.freebetBetType')"
        :description="formattedAllowedBetTypes"
      />
    </TooltipModalBlock>
    <TooltipModalBlock
      v-if="rollingMultiplier"
      :title="t('bonuses.tooltipModalWager')"
      data-t="multiplier-block"
    >
      <TooltipModalBlockItem :termin="t('bonuses.modalRolling')">
        x{{ rollingMultiplier }}
      </TooltipModalBlockItem>
      <TooltipModalBlockItem
        v-if="formattedRollingMinBetRate"
        :termin="t('bonuses.tooltipModalSportMin')"
        :description="formattedRollingMinBetRate"
      />
      <TooltipModalBlockItem
        v-if="formattedRollingMaxBetRate"
        :termin="t('bonuses.tooltipModalSportMax')"
        :description="formattedRollingMaxBetRate"
      />
      <TooltipModalBlockItem
        v-if="filteredCategoriesNames?.length"
        :termin="t('bonuses.freespinModalCasino')"
      >
        <div class="column">
          <span v-for="category in filteredCategoriesNames" :key="category">
            {{ category }}
          </span>
        </div>
      </TooltipModalBlockItem>
    </TooltipModalBlock>
  </BaseTooltipModal>
</template>

<script setup lang="ts">
import { useRollingData } from '../../composables/useRollingData'
import { useFreebetsStore } from '../../stores/useFreebetsStore'
import { useFreebet } from '../../composables/useFreebet'
import BaseTooltipModal from '../TooltipModal/BaseTooltipModal.vue'
import TooltipModalBlock from '../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../TooltipModal/TooltipModalBlockItem.vue'

const props = defineProps<{
  id: string
}>()

const { t } = useI18n()
const readMoreUrl = usePublicFileUrl('publicFileFreebetTerms')

const { freebets } = storeToRefs(useFreebetsStore())
const freebet = computed(
  () => freebets.value.filter((item) => item.id === Number(props.id))[0],
)

const {
  minBetRate,
  maxBetRate,
  formattedMinBetRate,
  formattedMaxBetRate,
  freebetAmount,
  formattedAllowedBetTypes,
} = useFreebet(freebet)

const rollingData = computed(() => {
  const {
    rollingMultiplier = '0',
    rollingPeriodInHours = 0,
    rollingMinBetRate = null,
    rollingMaxBetRate = null,
    rollingCasinoCategoryIds = null,
  } = freebet.value?.freebetRollingSettings ?? {}

  return {
    rollingMultiplier,
    rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  }
})

const {
  rollingMultiplier,
  filteredCategoriesNames,
  formattedRollingMinBetRate,
  formattedRollingMaxBetRate,
} = useRollingData(rollingData)
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
}
</style>
